import * as React from 'react';
import { graphql } from 'gatsby';
import { Grid } from '@material-ui/core';
import { ContentfulCarteraModelo } from '../../types/graphql-types';
import FlashLayout from '../components/FlashLayout';
import IconTitle from '../components/IconTitle';
import { useCarteraModeloPost } from '../operations/queries';
import getCategoryColor from '../utils/getCategoryColor';
import { Cartera as CarteraProps } from '../models/Cartera';
import options from '../utils/getRichTextOptions';
import Index from '../assets/icons/icon-Index.svg';
import Airtable from '../components/Airtable';
import LightboxCarousel from '../components/LightboxCarousel';
import PDFPreview from '../components/PdfPreview';
import { renderRichText } from "gatsby-source-contentful/rich-text"
import useIsClient from '../utils/isClient';
import { HeadApi } from '../components/HeadApi';

interface Props {
  data: {
    contentfulCarteraModelo: ContentfulCarteraModelo;
  };
}

export const Head = ({data}) => {
  const post: CarteraProps = useCarteraModeloPost(data.contentfulCarteraModelo);
  // console.log("[POST HEAD]", post);
  return(
    <HeadApi
      title={post.title}
      description={post.description}
      img={post.fluid?.gatsbyImageData.images.fallback.src}
    />
  );
};

export default function CarteraModelo({ data }: Props): JSX.Element {
  const color = getCategoryColor('rentaVariable');
  const post: CarteraProps = useCarteraModeloPost(data.contentfulCarteraModelo);
  // console.log('post');
  // console.log(post);
  const isClient = useIsClient();
  if(isClient){

    return (
      <FlashLayout post={post} color={color}>
        <>
          <h2>{post.description}</h2>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={3} />
  
            <Grid item xs={12} sm={12} md={6}>
              {post.text && renderRichText(post.text, options)}
            </Grid>
            <Grid item xs={12} sm={12} md={3} />
            <Grid item xs={12} sm={12} md={3} />
            <Grid item xs={12} sm={12} md={6}>
              {post.images && <LightboxCarousel images={post.images} />}
              {post.pdf && (
                <div>
                  <PDFPreview pdf={post.pdf} />
                </div>
              )}
            </Grid>
  
            {post.table && post.table?.tipo === '50%' && (
              <>
                <Grid item xs={12} sm={12} md={3} />
                <Grid item xs={12} sm={12} md={3} />
                <Grid item xs={12} sm={12} md={6}>
                  <IconTitle icon={Index} title={post.table.titulo}>
                    <Airtable
                      // key={post.table.titulo}
                      src={post.table.tabla}
                      description={post.table.descripcion}
                      // card={post.table.tipo === 'tarjeta'}
                      card={false}
                    />
                  </IconTitle>
                </Grid>
              </>
            )}
            {post.table && post.table?.tipo === '100%' && (
              <>
                <IconTitle icon={Index} title={post.table.titulo}>
                  <Airtable
                    // key={post.table.titulo}
                    src={post.table.tabla}
                    description={post.table.descripcion}
                    // card={post.table.tipo === 'tarjeta'}
                    card={false}
                  />
                </IconTitle>
              </>
            )}
            {post.table && post.table?.tipo === 'tarjeta' && (
              <>
                <Grid item xs={12} sm={12} md={3} />
                <Grid item xs={12} sm={12} md={3} />
                <Grid item xs={12} sm={12} md={6}>
                  <IconTitle icon={Index} title={post.table.titulo}>
                    <Airtable
                      // key={post.table.titulo}
                      src={post.table.tabla}
                      description={post.table.descripcion}
                      card={true}
                      // card={false}
                    />
                  </IconTitle>
                </Grid>
              </>
            )}
          </Grid>
        </>
      </FlashLayout>

    );
  }else{
    return(<></>)
  }
}

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulCarteraModelo(slug: { eq: $slug }) {
      ...carteraModelo
    }
  }
`;
